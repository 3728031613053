export const DESIGN_DETAIL_LAYOUT_CLASSLIST = [
  'design-detail-layout',
  'gl-overflow-hidden',
  'gl-m-0',
];

export const ACTIVE_DISCUSSION_SOURCE_TYPES = {
  pin: 'pin',
  discussion: 'discussion',
  url: 'url',
};
